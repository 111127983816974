
// メタデータ
export const bustArr= ["未設定", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"]
export const rootUri = "https://heaven-himeji.com"
export const apiRoot = "https://api.heaven-himeji.com/api"
export const diaryApiRoot = "https://api.marineblue-g.com/api"
export const hvnUriRoot = "https://www.cityheaven.net/hyogo/A2804/A280401/heaven_himegi"

// ヘブンブログパーツ類
export const hvnShopId = "2710021250";

export const hvnApi = {
    Diary:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=2&type=20&limitedKind=0&num=8",
    Video:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=4&type=14&num=5&col=3&color=2&fontcolor=10&btncolor=6&fontsize=15",
    Comment: "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=8&type=18&num=5&color=8&fontcolor=10&btncolor=6&fontsize=15",
}

// 店舗情報
export const shopInfo = {
    name:       "チューリップ姫路店",
    tel:        "079-288-1881",
    openTime:   "9:00~24:00",
    zipCode:    "670-0905",
    zipAddress: "兵庫県姫路市魚町112",
    gmapUri:    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3274.9613338697936!2d134.6857687505871!3d34.8320646803048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3554e01161e3737b%3A0xe7942831f03925f5!2z44CSNjcwLTA5MDUg5YW15bqr55yM5aer6Lev5biC6a2a55S677yR77yR77yS!5e0!3m2!1sja!2sjp!4v1663309619214!5m2!1sja!2sjp",
};

export const priceChart = "/img/20240426-price.jpg"
export const priceChart0426 = "/img/20240923-price.jpg"

// 20240426 料金表タイマー関数追加 system.js側も参照
export const getPriceChart=(triggerDate)=>{

    const trigger = new Date(triggerDate)
    const now = new Date()

    /* 動作確認する時にCO消して使ってください.
    console.log("現在時刻: ")
    console.log(now)
    console.log("表示差し替え時刻: ")
    console.log(trigger)
    */

    if(now.getTime() > trigger.getTime()){

        return priceChart0426

    }
    return priceChart
}

export const areaName = "姫路"

/* 以下、ページ内表示物など */

export const linkNavs = [
        {name: "ホーム",          path: "/home", blank: false, img: "/img/ico/i-home.png"},
        {name: "出勤表",          path: "/schedule", blank: false, img: "/img/ico/i-schedule.png"},
        {name: "新人一覧",        path: "/newface", blank: false, img: "/img/ico/i-newface.png"},
        {name: "在籍一覧",        path: "/allcasts", blank: false, img: "/img/ico/i-rec-women.png"},
        {name: "料金システム",     path: "/system", blank: false, img: "/img/ico/i-price.png"},
        {name: "アクセス", path: "/access", blank: false, img: "/img/ico/i-map.png"},
        {name: "動画一覧", path: hvnUriRoot + "/shopmovie", blank: true, img: "/img/ico/i-video.png"},
        {name: "写メ日記", path: hvnUriRoot + "/diarylist", blank: true, img: "/img/ico/i-diary.png"},
        {name: "口コミ", path: hvnUriRoot + "/reviews", blank: true, img: "/img/ico/i-review.png"},
        {name: "ネット予約", path: hvnUriRoot + "/A6ShopReservation", blank: true, img: "/img/ico/i-reserve.png"},
        {name: "男子求人", path: "https://mensheaven.jp/8/heaven_himegi/?of=y", blank: true, img: "/img/ico/i-rec-men.png"},
        {name: "女子求人", path: "https://www.girlsheaven-job.net/hyogo/ma-47/sa-463/heaven_himegi/?of=y", blank: true, img: "/img/ico/i-rec-women.png"},
];


export const slideArr = [
    {
        link: {
            blank: false,
            uri: null },
        img: {
            alt: "",
            imgPathSp: "https://api.marineblue-g.com/api/topbanner?t=heaven_himeji",
        },
    },

    {
        link: {
            blank: false,
            uri: null },
        img: {
            alt: "",
            imgPathSp: "/img/slides/20241010_s02-pc.jpg",
        },
    },

    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "",
        imgPathSp: "/img/slides/20241010_s03-pc.jpg",
        },
    },

];